<template>
  <div class="main-left-menu" :class="{ wide: wide }">
    <div v-if="toggleButtonFlg" class="menu-head" :class="{ wide: wide }">
      <img
        class="icon three-line"
        :class="{ wide: wide }"
        src="@/assets/images/menu.png"
        width="27px"
        height="20px"
        alt="menu"
        @click="toggleWide"
      />
    </div>
    <div class="menu-item-list">
      <div class="menu-item" v-for="item in menuItems" :key="item.name">
        <base-main-left-menu-item
          :item="item"
          :wide="wide"
          :isSelected="checkSelected(item)"
          :data-test="item.name"
        />
      </div>
    </div>

    <!-- ↓↓ サンプル -->
    <router-link :to="'/main/sample'" v-if="devMode">
      <div
        :style="{
          backgroundColor: 'orange',
          height: '60px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }"
      >
        サンプル
      </div>
    </router-link>
    <!-- ↑↑ サンプル -->

    <div class="menu-foot" data-e2e="settings-button">
      <router-link
        class="footer-link"
        :class="{ wide: wide }"
        to="/settings/common/base"
      >
        <img
          class="icon gear"
          src="@/assets/images/setting_gear_orange.png"
          width="27px"
          height="27px"
          alt="setting-gear-orange"
        />
        <div v-if="wide" class="text">設定</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import BaseMainLeftMenuItem from '@/components/parts/atoms/BaseMainLeftMenuItem.vue'
import DetectWindowSize from '@/components/mixins/DetectWindowSize'
import { mapGetters } from 'vuex'
export default {
  name: 'MainLeftMenu',
  components: { BaseMainLeftMenuItem },
  mixins: [DetectWindowSize],
  data() {
    return {
      standardMenuItems: [
        {
          name: 'owners',
          value: '飼主・患者',
          icon: 'owners.png',
          link: '/main/owners',
          baseLink: '/main/owners'
        },
        {
          name: 'reservations',
          value: '予約',
          icon: 'calendar.png',
          link: '/main',
          baseLink: '/main'
        }
      ],
      karteMenuItems: [
        {
          name: 'karte',
          value: 'カルテ',
          icon: 'karte.png',
          link: '/main/karte/medical-records',
          baseLink: '/main/karte'
        },
        {
          name: 'payments',
          value: '会計',
          icon: 'payment.png',
          link: '/main/payments',
          baseLink: '/main/payments'
        },
        {
          name: 'hospitalizations',
          value: '入院',
          icon: 'hospitalization.png',
          link: '/main/hospitalizations',
          baseLink: '/main/hospitalizations'
        },
        {
          name: 'reports',
          value: 'レセプト',
          icon: 'reports.png',
          link: '/main/anicom-reports',
          baseLink: '/main/anicom-reports'
        },
        {
          name: 'vaccinations',
          value: '予防接種',
          icon: 'vaccination.png',
          link: '/main/vaccinations',
          baseLink: '/main/vaccinations'
        },
        {
          name: 'analytics',
          value: '集計',
          icon: 'analytics.png',
          link: '/main/analytics/line-graph',
          baseLink: '/main/analytics/line-graph'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      karteFlg: 'auth/karteFlg'
    }),
    wide() {
      return this.$store.getters['petorelu/get'].leftWideFlg
    },
    toggleButtonFlg() {
      return this.$store.getters['petorelu/get'].toggleButtonFlg
    },
    path() {
      return this.$route.path
    },
    menuItems() {
      return this.karteFlg
        ? [...this.standardMenuItems, ...this.karteMenuItems]
        : this.standardMenuItems
    },
    devMode() {
      return process.env.NODE_ENV === 'development'
    }
  },
  methods: {
    toggleWide() {
      this.$store.dispatch('petorelu/toggleLeft')
    },
    toSettings() {
      this.$router.push({ path: '/settings/common/base' })
    },
    checkSelected(item) {
      return item.link === '/main'
        ? this.path === '/main'
        : this.path.includes(item.baseLink)
    }
  }
}
</script>

<style lang="scss" scoped>
.main-left-menu {
  box-sizing: border-box;
  border-right: solid 1px #{$light-grey};
  display: flex;
  flex-direction: column;
  width: 58px;
  min-width: 58px;
  font-size: 15px;
  overflow-y: auto;
  &.wide {
    width: 240px;
    min-width: 240px;
  }
  > .menu-head {
    height: 60px;
    display: flex;
    justify-content: center;
    &.wide {
      justify-content: right;
    }
    > .three-line {
      margin: 20px 0;
      &.wide {
        margin: 20px;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
  > .menu-foot {
    margin-top: auto;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    > .footer-link {
      height: 60px;
      display: flex;
      align-items: center;
      &.wide {
        justify-content: left;
        padding-left: 20px;
      }
      &:not(.wide) {
        justify-content: center;
      }
      > .text {
        margin-left: 10px;
      }
    }
  }
}
</style>
